






import { Component, Prop } from 'vue-property-decorator'

import { QuoteAuthor } from '../../../molecules'

import { AbstractModuleUiPresentation } from '../../_abstract'

import { QuoteModuleContent } from '../Quote.contracts'

/**
 * Presentational component for the `QuoteModuleUi`.
 *
 * @emits submit - When the User submits the form.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
@Component<QuoteModuleUiPresentation>({
  name: 'QuoteModuleUiPresentation',
  components: {
    Quote: () => import(
      /* webpackChunkName: "molecules/Quote" */
      '../../../molecules/Quote/Quote.vue'
    )
  }
})
export class QuoteModuleUiPresentation extends AbstractModuleUiPresentation {
  /**
   * @see QuoteModuleContent.author
   */
  @Prop({ type: Object, required: false })
  public readonly author?: QuoteAuthor

  /**
   * @see QuoteModuleContent.body
   */
  @Prop({ type: String, required: true })
  public readonly body!: QuoteModuleContent['body']

  /**
   * @see QuoteModuleContent.heading
   */
  @Prop({ type: String, required: false })
  public readonly heading?: QuoteModuleContent['heading']

  /**
   * @see QuoteModuleContent.icon
   */
  @Prop({ type: String, required: false })
  public readonly icon?: QuoteModuleContent['icon']

  /**
   * @see QuoteModuleContent.textAlignment
   */
  @Prop({ type: String, required: false })
  public readonly textAlignment?: QuoteModuleContent['textAlignment']

  /**
   * Determines whether the component has been provided with the correct `body` @Prop.
   */
  public get hasBody (): boolean {
    return typeof this.body === 'string' && this.body.length > 0
  }
}

export default QuoteModuleUiPresentation
