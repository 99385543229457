








import { Component } from 'vue-property-decorator'

import { isRelated } from '../../../../services'
import { log } from '../../../../support'

import { QuoteAuthor } from '../../../molecules'

import { AbstractModuleUi } from '../../_abstract'

import { QuoteModule } from '../Quote.contracts'

/**
 * Container component for the `QuoteModuleUi`.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component<QuoteModuleUi>({
  name: 'QuoteModuleUi',
  components: {
    QuoteModuleUiPresentation: () => import(
      /* webpackChunkName: "modules/Quote" */
      './Quote.ui.presentation.vue'
    )
  }
})
export class QuoteModuleUi extends AbstractModuleUi<QuoteModule> {
  /**
   * @see QuoteModuleContent.author.image
   */
  public authorImage: QuoteAuthor['image'] | null = null

  /**
   * @see QuoteAuthor
   */
  public get author (): QuoteAuthor | undefined {
    if (!this.hasContent) {
      const message: string = 'QuoteModuleUi.hasContent(): [this.data.content] is [undefined]!'
      log(message, 'error')
      return
    }

    if (!this.data.content.author) {
      return
    }

    return {
      ...this.data.content.author,
      image: this.authorImage ? this.authorImage : undefined
    }
  }

  /**
   * Determines whenever content has defined author
   */
  private get hasAuthor () {
    return this.hasContent && typeof this.author !== 'undefined'
  }

  /**
   * @inheritDoc
   */
  public async fetchRelated (): Promise<void> {
    await this._fetchAuthorImage()
  }

  /**
   * Fetches actual author image from the `RelatedService`.
   */
  private async _fetchAuthorImage (): Promise<void> {
    if (!this.hasAuthor) {
      return
    }

    // Getter `hasAuthor` check whenever author is define or not, but TS doesn't understand that.
    // That's why we need to use `?`
    const unresolvedImage = this.data.content.author?.image

    if (!isRelated(unresolvedImage)) {
      return
    }

    try {
      this.authorImage = await this.fetchImage(unresolvedImage)
    } catch (error) {
      const message: string =
        'QuoteModuleUi._fetchAuthorImage(): Failed to fetch the related image from the [RelatedService]!'
      log(message, 'error')
    }
  }
}

export default QuoteModuleUi
